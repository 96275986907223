<template>
	<div>
		<pui-datatable
			ref="documentextrafieldsportcallgrid"
			:modelName="modelName"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
			:modelColumnDefs="modelColumnDefs"
			:masterDetailColumnVisibles="masterDetailColumnVisibles()"
			:readOnly="readOnly"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'documentextrafieldsportcall-grid',
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'documentextrafieldsportcall',
			statusportcalls: null,
			modelColumnDefs: {
				expires: {
					render: (data) => {
						if (!data) {
							return '';
						}
						const partesFecha = data.split('-');
						const fecha = new Date(partesFecha[2], partesFecha[1] - 1, partesFecha[0]);
						fecha.setDate(fecha.getDate() + 1);

						const dia = fecha.getDate();
						const mes = fecha.getMonth() + 1;
						const año = fecha.getFullYear();
						const nuevaStringFecha = `${dia < 10 ? '0' : ''}${dia}-${mes < 10 ? '0' : ''}${mes}-${año}`;

						return nuevaStringFecha;
					}
				},
				issued: {
					render: (data) => {
						if (!data) {
							return '';
						}

						const partesFecha = data.split('-');

						const fecha = new Date(partesFecha[2], partesFecha[1] - 1, partesFecha[0]);

						fecha.setDate(fecha.getDate() + 1);

						const dia = fecha.getDate();
						const mes = fecha.getMonth() + 1;
						const año = fecha.getFullYear();

						const nuevaStringFecha = `${dia < 10 ? '0' : ''}${dia}-${mes < 10 ? '0' : ''}${mes}-${año}`;

						return nuevaStringFecha;
					}
				}
			}
		};
	},

	methods: {
		masterDetailColumnVisibles() {
			let visibleColumns = {
				typedescription: true,
				description: true,
				uploaddate: true,
				expires: true
			};

			if (this.parentModelName === 'portcalls') {
				visibleColumns = {
					typedescription: true,
					description: true,
					uploaddate: true,
					operation: true
				};
			}
			return visibleColumns;
		},
		createFilter() {
			const bpk = this.$puiUtils.b64ToUtf8(this.parentPk);
			const parentPkObject = JSON.parse(bpk);
			let pkValue = null;

			for (var key in parentPkObject) {
				const value = parentPkObject[key];

				pkValue = !pkValue ? value : pkValue + '#' + value;
			}

			const filter = { groupOp: 'and', rules: [], groups: [] };
			filter.rules.push({ field: 'model', op: 'eq', data: this.parentModelName });
			filter.rules.push({ field: 'pk', op: 'eq', data: pkValue });

			return filter;
		}
	}
};
</script>
